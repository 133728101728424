export const environment = {
  baseUrl: 'https://app.lektory.app',

  firebase: {
    config: {
      projectId: 'lektory-prod',
      appId: '1:372847788538:web:c02730abb1197f6470e443',
      storageBucket: 'lektory-prod.appspot.com',
      apiKey: 'AIzaSyDyV749Cvj9jQhtcTJOlnRTVDnpK_SK4Iw',
      authDomain: 'lektory-prod.firebaseapp.com',
      messagingSenderId: '372847788538',
    },
    appCheck: {
      recaptcha3SiteKey: '6LeUZGIhAAAAACfyKfwtZ4OlLn1ib3AlP4sAx8XN',
      isTokenAutoRefreshEnabled: true,
      debug: ''
    }
  },
  production: true,
  local: false
};
